import React, { useState, useEffect, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import Loading from "../layout/loading";
import { fetchLogin } from '../services/authenService';

// import Footer from "../../layout/footer"


import banner from "../assets/img/banner2.jpg";


const Main = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [remember, setRemember] = useState({ email: '', pass: '' })
    const [result, setResult] = useState()
    const [validated, setValidated] = useState(false);
    const [isChecked, setIsChecked] = useState(false)

    let componentMounted = useRef(true);

    useEffect(() => {

        if (componentMounted.current) {
            if (localStorage.getItem('accessToken'))
                window.location.href = "/profile";

            if (localStorage.getItem('remember')) {
                setRemember({ email: JSON.parse(localStorage.getItem('remember')).email, pass: '' })
                setIsChecked(true)
                // console.log(localStorage.getItem('remember'))
            }
        }

        return () => {
            componentMounted.current = false;
        }
    }, [])

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            setIsLoading(true);

            await fetchLogin(dataForm).then(
                response => {
                    if (response.status === 'success') {

                        if (dataForm.get('remember')) {
                            // let arr = {'user':dataForm.get('username'),'pass':dataForm.get('password')};
                            let arr = { 'email': dataForm.get('email'), 'pass': '' };
                            localStorage.setItem('remember', JSON.stringify(arr))
                        }

                        setTimeout(() => {
                            window.location.href = "/choose";
                            // alert(5)
                        }, 100);
                    }
                }).catch(e => {
                    setIsLoading(false);
                    // setResult(e.response.data.error)
                    // console.log(e.response.data.error)

                })
        }
        setValidated(true);
    };

console.log(remember)

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100" style={{ backgroundImage: `url(${banner})` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    {/* <img src={banner} className="w-100" alt="" /> */}
                                    <Link to="/" className="btn-default"><h2 className="my-3 m-md-5 weight-bold">EAEA2024</h2></Link>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 text-center mt-4">
                                    <h1 className="text-green weight-bold mb-5">LOG IN</h1>
                                    <div className="px-4">
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">                                                
                                                <Form.Control type="email" name="email" defaultValue={remember.email} className="input-custom input-login" placeholder="Email" required />
                                            </Form.Group>
                                            <Form.Group className="mb-1 position-relative" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="password" name="password" className="input-custom input-login" placeholder="Password"
                                                    required />
                                            </Form.Group>
                                            {/* <div className="a-default">{result}</div> */}
                                            <div className="row pt-4 text-start">
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                        <div>
                                                            <div className="ex-checkbox4 d-inline-block align-middle me-4">
                                                                <input type="checkbox" name="remember" value="1" checked={isChecked ? true : false} onChange={e => setIsChecked(!isChecked)}/>
                                                                <label className="radio_one font-size-18">
                                                                    <span className='ps-2'>Keep me Log in</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 text-end">
                                                    <a href="" className="py-0 btn a-default">Forgot password?</a>
                                                </div>
                                            </div>

                                            <Form.Group className="mt-4" controlId="exampleForm.ControlInput1">
                                                <div className="">
                                                    <button type="submit" className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>LOG IN</button>
                                                </div>
                                            </Form.Group>

                                        </Form>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
