import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Loading from "../layout/loading";
import { authUser, getUser } from '../services/authenService';
import { getData } from "../services/getService";

import Header from "../layout/header"

import banner from "../assets/img/banner-choose.jpg";
import calendar from "../assets/img/icon/Calendar.svg";

import moment from 'moment';

const Main = () => {

    const [auth, setAuth] = useState(authUser())
    const [isLoading, setIsLoading] = useState(true);

    const [memberData, setMemberData] = useState({});
    const [countryData, setCountryData] = useState({})

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataMember()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchDataMember = async () => {
        if (!auth || !auth.id)
            setTimeout(() => { window.location.reload() }, 500);

        getData('member', auth.id).then(
            response => {
                if (response.status === 'success') {
                    setMemberData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }


    return (
        <>
            <div id="outer-container" style={{}}>

                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <Header page="home" />
                            <div className="row align-items-center justify-content-center text-start">

                                <div className="col-12 col-md-11 col-lg-12">
                                    <div className="mx-3 mx-md-5 mt-3">
                                        <img src={banner} className="w-100" alt="" />
                                        <div className="my-5">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <h1 className="text-green weight-bold">EAEA2024</h1>
                                                    <h5 className="weight-medium">19th East Asian Economic Association International conference</h5>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 text-md-end pt-3 pt-md-0">
                                                    <h3 className="weight-bold d-inline-block mb-0">
                                                        <img src={calendar} className="" alt="" style={{ width: '30px' }} />
                                                        <span className="ms-2">{moment().format('DD MMM YYYY')}</span>
                                                    </h3>
                                                    <h1 className="text-green weight-bold d-inline-block" style={{ width: '150px' }}>{memberData.symbol}{memberData.price && memberData.price.toLocaleString()}</h1>
                                                    {memberData.unit === 'US' && <>
                                                        <h4><span>Exchange rate</span><span className="d-inline-block" style={{ width: '150px' }}>{memberData.rate && memberData.rate.exchange}</span></h4>
                                                        <h4><span>Equivalent to</span><span className="d-inline-block" style={{ width: '150px' }}>฿{memberData.rate && (memberData.rate.exchange*memberData.price).toLocaleString()}</span></h4>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="mt-4 mb-5 text-center" >
                                            <Link to="/checkout" className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>CHECK OUT</Link>

                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
