import React, { useEffect, useRef } from "react";

const Main = () => {

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            setTimeout(() => {
                window.location.href = "/profile";         
            }, 3000);
        }
        return () => {
            called.current = false
        }

    }, [])


    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {/* {isLoading ? <Loading /> : null} */}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-11 col-lg-12">
                                    <div className="mx-5">
                                        <div className="my-5">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-lg-6 text-center">
                                                    <div className="box-thankyou">
                                                        <h1 className="text-white weight-bold">THANK YOU</h1>
                                                        <h2 className="text-white weight-medium">PAYMENT RECEIVED</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
