import React, { useState, useEffect, useRef } from "react";

import Loading from "../layout/loading";
import Header from "../layout/header";

const Main = () => {

    const [isLoading, setIsLoading] = useState(false);

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            setTimeout(() => {
                window.location.href = "/profile";
                // alert(5)
            }, 4000);
        }
        return () => {
            called.current = false
        }
    }, [])

    return (
        <>
            <div id="outer-container" style={{}}>
                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <Header page="home" />
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12 text-center">
                                    {/* <div className="box-thankyou text-center">
                                        <h1 className="text-white weight-bold">THANK YOU</h1>
                                        <h2 className="text-white weight-medium">PAYMENT RECEIVED</h2>
                                    </div> */}
                                    {/* <div className="card mt-5">
                                        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
                                            <i className="checkmark">✓</i>
                                        </div>
                                        <h1 className="mt-3">Success</h1>
                                        <p>THANK YOU<br /> PAYMENT RECEIVED</p>
                                    </div> */}
                                    <div className="container mt-5">                                        
                                        <div className="row justify-content-center">
                                            <div className="col-md-5">
                                                <div className="message-box _success _failed">
                                                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                                                    <h2> Your payment failed </h2>
                                                    <p>  Try again later </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Main;
