import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import users from "../assets/img/icon/Ellipse.svg";
import menu from "../assets/img/icon/Menu.svg";
import menuClose from "../assets/img/icon/MenuClose.svg";

import { authUser, fetchLogout } from '../services/authenService';



const LoadingSpinner = () => {

    const [auth, setAuth] = useState(authUser())
    const [bmenu, setBmenu] = useState()

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            fetchUser()
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchUser = async () => {
        console.log(auth)
        if (!auth || !auth.id)
            setTimeout(() => { window.location.reload() }, 500);

        const userData = await authUser()
        // console.log('user data : ', userData)
        setAuth(userData)

        // console.log('auth : ', auth.id)
        // const user = await getUser(auth.id)

        // if( user.status === 200 || user.status === 'success'){

        // }
    }

    const handleLogout = () => {
        fetchLogout()
    }

    return (
        <div className="row align-items-center justify-content-center text-start">
            <div className="col-5 col-md-6 col-lg-6">
                <Link to="/" className="btn-default"><h2 className="my-3 m-md-5 weight-bold">EAEA2024</h2></Link>
            </div>
            <div className="col-7 col-md-6 col-lg-6 text-end">
                <div className="my-3 m-md-5">
                    <Link to="/profile" className="btn-default">{auth && auth.firstName + ' ' + auth.lastName}
                        <img src={users} className="ms-3" alt="" style={{ width: '40px' }} />
                    </Link>
                    <div className={`d-inline-block position-relative ${bmenu ? "active" : ""}`} >
                        <img src={`${bmenu ? menuClose : menu}`} className="ms-3 cursor-pointer" alt="" style={{ width: '50px' }} onClick={e => setBmenu(!bmenu)} />

                        <div className="position-absolute box-menu-res text-center p-2" style={{}}>
                            <Link to="/profile" className="btn">Profile</Link>
                            <button className="btn" onClick={handleLogout}>Log out</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default LoadingSpinner;