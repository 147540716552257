import React, { useState, useEffect, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import Loading from "../layout/loading";
import { fetchRegister } from "../services/authenService";
// import { countryData } from "../configs/country";
import { getDataNone } from "../services/getService";

import moment from 'moment';

const Main = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [result, setResult] = useState({ name: '', email: '' })
    const [countryData, setCountryData] = useState([])

    const [validated, setValidated] = useState(false);

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }

    }, [])

    const fetchData = async () => {
       
        getDataNone('country').then(
            response => {
                if (response.status === 'success') {
                    setCountryData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            setIsLoading(true);

            dataForm.set('dateActive', moment().format('YYYY-MM-DD'));
            await fetchRegister(dataForm).then(
                response => {
                    if (response.status === 'success') {
                        // console.log(response)
                        Swal.fire({
                            position: "center-center",
                            icon: "success",
                            html: "<div className='mb-5'>Completed registration</div>",
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            setIsLoading(false)

                            setTimeout(() => {
                                window.location.href = "/login";
                            }, 100);
                        });
                    }
                }).catch(e => {
                    setIsLoading(false)
                   
                    if (e.response.status === 409 && e.response.data.messages.errors) {
                        // if (e.response.data.messages.errors.name) {
                        //     setResult((prev) => ({ ...prev, name: e.response.data.messages.errors.name }))
                        // }

                        if (e.response.data.messages.errors.email) {
                            setResult((prev) => ({ ...prev, email: e.response.data.messages.errors.email }))
                        }
                    }
                })
        }
        setValidated(true);
    };



    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    {/* <img src={banner} className="w-100" alt="" /> */}
                                    <Link to="/" className="btn-default"><h2 className="my-3 m-md-5 weight-bold">EAEA2024</h2></Link>
                                </div>
                                <div className="col-12 col-md-11 col-lg-8 text-center mt-4">
                                    <h1 className="text-green weight-bold">SIGN UP</h1>
                                    <h5 className="weight-medium">Please fill in your personal information</h5>
                                    <div className="px-4 mt-5">
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" name="firstName" className="input-custom input-login" placeholder="First name*" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" name="lastName" className="input-custom input-login" placeholder="Last name*" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="email" name="email" className="input-custom input-login" placeholder="Email*" required />
                                                        <div className="text-start mt-2" style={{color:'#dc3545',fontSize:'.875em'}}>{result.email}</div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="password" name="password" className="input-custom input-login" placeholder="Password*" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" name="position" className="input-custom input-login" placeholder="Position*" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Select className="input-custom select-login" name="graduate" aria-label="Default select example" required>
                                                            <option value="">Open this select*</option>
                                                            <option value="1">Graduate Students</option>
                                                            <option value="2">Non-graduate student</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" name="affiliation" className="input-custom input-login" placeholder="Affiliation*" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Select className="input-custom select-login" name="country" aria-label="Default select example" required>
                                                            <option value="">Country*</option>
                                                            {countryData.length && countryData.map((val, index) => (
                                                                <option value={val.id} key={index}>{val.countryName}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-6">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="text" name="accId" className="input-custom input-login" placeholder="Abstract Acceptance ID"/>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row pt-4 text-start">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <Form.Label className="weight-medium mb-1">Organized Session (s)</Form.Label>
                                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                        <div>
                                                            <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                <input type="radio" name="session" value="1" />
                                                                <label className="radio_one font-size-18">
                                                                    <span className='ps-2'>Yes (Please provide “institution / organization” for the session below)</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                        <div>
                                                            <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                <input type="radio" name="session" value="2" />
                                                                <label className="radio_one font-size-18">
                                                                    <span className='ps-2'>No</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row pt-4 text-start">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <Form.Label className="weight-medium mb-1">Institution / Organization (for organized session)</Form.Label>
                                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                        <textarea className="form-control input-custom input-login" name="institution" rows="5"></textarea>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="row pt-4 text-start">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <Form.Label className="weight-medium mb-1">Member*</Form.Label>

                                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                        <div>
                                                            <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                <input type="radio" name="member" value="1" required />
                                                                <label className="radio_one font-size-18">
                                                                    <span className='ps-2'>Paid-up EAEA member</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                        <div>
                                                            <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                <input type="radio" name="member" value="2" required />
                                                                <label className="radio_one font-size-18">
                                                                    <span className='ps-2'>Non EAEA member</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <input type="radio" style={{ opacity: 0 }} name="member" value="0" className="position-absolute" required />
                                                    <div className="invalid-feedback">
                                                        Please select a valid member.
                                                    </div>
                                                </div>
                                            </div>
                                            <Form.Group className="mt-4 mb-5" controlId="exampleForm.ControlInput1">
                                                <div className="">
                                                    <button type="submit" className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>SIGN UP</button>
                                                </div>
                                            </Form.Group>

                                        </Form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
