import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import Loading from "../layout/loading";
import { fetchRegister } from "../services/authenService";
import { authUser, getUser } from '../services/authenService';
import { getData, getDataNone,putData } from "../services/getService";

import Header from "../layout/header"

import calendar from "../assets/img/icon/Write.svg";

const Main = () => {

    const [auth, setAuth] = useState(authUser())
    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState({ name: '', email: '' })
    const [memberData, setMemberData] = useState({});

    const [countryData, setCountryData] = useState([])
    const [validated, setValidated] = useState(false);

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataMember(), fetchData()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])


    const fetchDataMember = async () => {
        if (!auth || !auth.id)
            setTimeout(() => { window.location.reload() }, 500);

        getData('member', auth.id).then(
            response => {
                if (response.status === 'success') {
                    setMemberData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const fetchData = async () => {

        getDataNone('country').then(
            response => {
                if (response.status === 'success') {
                    setCountryData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            setIsLoading(true);

            await putData('member',memberData.id,dataForm).then(
                response => {
                    if (response.status === 'success') {
                        // console.log(response)
                        Swal.fire({
                            position: "center-center",
                            icon: "success",
                            html: "<div className='mb-5'>Update Completed</div>",
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            setIsLoading(false)

                            setTimeout(() => {
                                window.location.href = "/login";
                            }, 100);
                        });
                    }
                }).catch(e => {
                    setIsLoading(false)

                    if (e.response.status === 409 && e.response.data.messages.errors) {
                        // if (e.response.data.messages.errors.name) {
                        //     setResult((prev) => ({ ...prev, name: e.response.data.messages.errors.name }))
                        // }

                        if (e.response.data.messages.errors.email) {
                            setResult((prev) => ({ ...prev, email: e.response.data.messages.errors.email }))
                        }
                    }
                })
        }
        setValidated(true);
    };


    return (
        <>
            <div id="outer-container" style={{}}>

                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <Header page="home" />
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="mx-3 mx-md-5">
                                        <div className="my-4" style={{ borderBottom: '1px solid #000' }}>
                                            <div className="row align-items-end">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <h1 className="text-green weight-bold">EDIT MY PROFILE</h1>
                                                    <h4 className="weight-medium">Personal Information</h4>
                                                </div>                                                
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="text" name="firstName" defaultValue={memberData.firstName} className="input-custom input-login" placeholder="First name*" required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="text" name="lastName" defaultValue={memberData.lastName} className="input-custom input-login" placeholder="Last name*" required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="email" name="email" readOnly defaultValue={memberData.email} className="input-custom input-login" placeholder="Email*" required />
                                                            <div className="text-start mt-2" style={{ color: '#dc3545', fontSize: '.875em' }}>{result.email}</div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="password" name="password" className="input-custom input-login" placeholder="Password*" required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="text" name="position" defaultValue={memberData.position} className="input-custom input-login" placeholder="Position*" required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Select className="input-custom select-login" disabled onChange={e => setMemberData((prev) => ({ ...prev, graduate: e.target.value }))} value={memberData.graduate} name="graduate" aria-label="Default select example" required>
                                                                <option value="">Open this select*</option>
                                                                <option value="1">Graduate Students</option>
                                                                <option value="2">Non-graduate student</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-lg-3">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="text" name="affiliation" defaultValue={memberData.affiliation} className="input-custom input-login" placeholder="Affiliation*" required />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-3">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Select className="input-custom select-login" disabled onChange={e => setMemberData((prev) => ({ ...prev, country: e.target.value }))} value={memberData.country && memberData.country.id} name="country" aria-label="Default select example" required>
                                                                <option value="">Country*</option>
                                                                {countryData.length && countryData.map((val, index) => (
                                                                    <option value={val.id} key={index}>{val.countryName}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-6">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="text" name="accId" defaultValue={memberData.accId} className="input-custom input-login" placeholder="Abstract Acceptance ID" />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="row pt-4 text-start">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <Form.Label className="weight-medium mb-1">Organized Session (s)</Form.Label>
                                                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                            <div>
                                                                <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                    <input type="radio" name="session" value="1" onChange={e => setMemberData((prev) => ({ ...prev, session: "1" }))} checked={memberData.session === "1"} />
                                                                    <label className="radio_one font-size-18">
                                                                        <span className='ps-2'>Yes (Please provide “institution / organization” for the session below)</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                            <div>
                                                                <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                    <input type="radio" name="session" value="2" onChange={e => setMemberData((prev) => ({ ...prev, session: "2" }))} checked={memberData.session === "2"} />
                                                                    <label className="radio_one font-size-18">
                                                                        <span className='ps-2'>No</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="row pt-4 text-start">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <Form.Label className="weight-medium mb-1">Institution / Organization (for organized session)</Form.Label>
                                                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                            <textarea className="form-control input-custom input-login" defaultValue={memberData.institution} name="institution" rows="5"></textarea>
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                                <div className="row pt-4 text-start">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <Form.Label className="weight-medium mb-1">Member*</Form.Label>

                                                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                            <div>
                                                                <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                    <input type="radio" name="member" value="1" required disabled onChange={e => setMemberData((prev) => ({ ...prev, member: "1" }))} checked={memberData.member === "1"} />
                                                                    <label className="radio_one font-size-18">
                                                                        <span className='ps-2'>Paid-up EAEA member</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                            <div>
                                                                <div className="ex-radio4 d-inline-block align-middle me-4">
                                                                    <input type="radio" name="member" value="2" required disabled onChange={e => setMemberData((prev) => ({ ...prev, member: "2" }))} checked={memberData.member === "2"} />
                                                                    <label className="radio_one font-size-18">
                                                                        <span className='ps-2'>Non EAEA member</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                        <input type="radio" style={{ opacity: 0 }} name="member" value="0" disabled className="position-absolute" required />
                                                        <div className="invalid-feedback">
                                                            Please select a valid member.
                                                        </div>
                                                    </div>
                                                </div>
                                                <Form.Group className="mt-4 mb-5" controlId="exampleForm.ControlInput1">
                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>UPDATE</button>
                                                    </div>
                                                </Form.Group>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
