import React, { useState, useEffect, useRef, createRef } from "react";
import { Link } from "react-router-dom";

import Loading from "../layout/loading";
import { authUser, getUser } from '../services/authenService';
import { getData } from "../services/getService";

import Header from "../layout/header"

import calendar from "../assets/img/icon/Write.svg";
import dw from "../assets/img/icon/Download.svg";
import print from "../assets/img/icon/Print.svg";

import { useReactToPrint } from 'react-to-print';

import { useScreenshot, createFileName } from "use-react-screenshot";
import { PDFDocument } from 'pdf-lib'

const Main = () => {

    const [auth, setAuth] = useState(authUser())
    const [isLoading, setIsLoading] = useState(true);

    const [mainData, setMainData] = useState([]);
    const [memberData, setMemberData] = useState({});
    const [countryData, setCountryData] = useState({})

    const called = useRef(true)

    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataMember()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchDataMember = async () => {
        if (!auth || !auth.id)
            setTimeout(() => { window.location.reload() }, 500);

        getData('member', auth.id).then(
            response => {
                if (response.status === 'success') {
                    setMemberData(response.data)
                    // fetchData(response.data.country)
                    // fetchDataOrder(response.data.id)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    // const fetchData = async (id) => {

    //     getData('country',id).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 setCountryData(response.data)
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //         })
    // }
    // const fetchDataOrder = async (id) => {

    //     getData('order',id).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 setMainData(response.data)
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //         })
    // }

    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });


    const downloadScreenshot = () => { 
        setIsLoading(true)
        takeScreenShot(ref.current).then(
            setTimeout(() => {
                handlePrint(null, () => contentToPrint.current)
                setIsLoading(false);
            }, 1000)
        )
    }
    
    const download = (image, { name = "img", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };


    const downloadScreenshotPdf = () => 
        takeScreenShot(ref.current).then(            
            download
        )

    return (
        <>
            <div id="outer-container" style={{}} ref={ref}>

                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <Header page="home" />
                            <div className="row align-items-center justify-content-center text-start">
                                <div ref={contentToPrint} style={{ position: 'absolute', button: 0, zIndex: '-1' }}>
                                    <img width={'100%'} src={image} alt={"ScreenShot"} />
                                </div>

                                <div className="col-12 col-md-11 col-lg-12">
                                    <div className="mx-3 mx-md-5">
                                        <div className="my-4" style={{ borderBottom: '1px solid #000' }}>
                                            <div className="row align-items-end">
                                                <div className="col-10 col-md-6 col-lg-6">
                                                    <h1 className="text-green weight-bold">MY PROFILE</h1>
                                                    <h4 className="weight-medium">Personal Information</h4>
                                                </div>
                                                <div className="col-2 col-md-6 col-lg-6 text-end">

                                                    <Link to="/edit"><img src={calendar} className="pb-3" alt="" style={{ width: '30px' }} /></Link>
                                                    <button className="btn py-0" onClick={downloadScreenshotPdf}><img src={dw} className="pb-3" alt="" style={{ width: '38px' }} /></button>
                                                    <button className="btn p-0" onClick={downloadScreenshot}><img src={print} className="pb-3" alt="" style={{ width: '38px' }} /></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-4" style={{ borderBottom: '1px solid #000' }}>
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="mb-4">
                                                        <b>First name</b> <span className="ms-4">{memberData.firstName}</span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <b>Email</b> <span className="ms-4">{memberData.email}</span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <b>Country</b> <span className="ms-4">{memberData.country && memberData.country.countryName}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="mb-4">
                                                        <b>Last name</b> <span className="ms-4">{memberData.lastName}</span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <b>Position</b> <span className="ms-4">{memberData.position}</span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <b>Abstract Acceptance ID</b> <span className="ms-4">{memberData.accId}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="mb-4">
                                                        <b>Affiliation</b> <span className="ms-4">{memberData.affiliation}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-4">
                                            <table className="table table-bordered table-custom">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">No.</th>
                                                        <th>Topic</th>
                                                        <th className="d-none d-md-table-cell">Description</th>
                                                        <th>Price</th>
                                                        <th style={{ width: '160px' }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {memberData && memberData.order && memberData.order.length ? memberData.order.map((val, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">
                                                                {val.status === '1' ?
                                                                    <Link to={"/registrat/" + val.id} className="btn-default">{index + 1}</Link>
                                                                    :
                                                                    index + 1
                                                                }
                                                            </td>
                                                            <td>
                                                                {val.status === '1' ?
                                                                    <Link to={"/registrat/" + val.id} className="btn-default">EAEA2024</Link>
                                                                    :
                                                                    'EAEA2024'
                                                                }
                                                            </td>
                                                            <td className="d-none d-md-table-cell">
                                                                {val.status === '1' ?
                                                                    <Link to={"/registrat/" + val.id} className="btn-default">19th East Asian Economic Association International conference : Registration Fees</Link>
                                                                    :
                                                                    '19th East Asian Economic Association International conference : Registration Fees'
                                                                }
                                                            </td>
                                                            <td>
                                                                {val.status === '1' ?
                                                                    <Link to={"/registrat/" + val.id} className="btn-default">{memberData.symbol}{parseFloat(val.price).toLocaleString()}</Link>
                                                                    :
                                                                    memberData.symbol + '' + parseFloat(val.price).toLocaleString()
                                                                }
                                                            </td>
                                                            <td>
                                                                {val.status === '0' ? <span className="status-waiting">Waiting for approval</span> : val.status === '1' ?
                                                                    <span className="status-success">Success</span>
                                                                    : <span className="status-cancel">Cancel</span>}
                                                            </td>
                                                        </tr>
                                                    )) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
