import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import Loading from "../layout/loading";
import { authUser } from '../services/authenService';
import { getData } from "../services/getService";

import Header from "../layout/header"

import calendar from "../assets/img/icon/Vector.svg";

const Main = () => {

    const pr = useParams();

    const [auth, setAuth] = useState(authUser())
    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState({});
    const [memberData, setMemberData] = useState({});
    const [countryData, setCountryData] = useState({})

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataMember()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchDataMember = async () => {
        if (!auth || !auth.id)
            setTimeout(() => { window.location.reload() }, 500);

        getData('member', auth.id).then(
            response => {
                if (response.status === 'success') {
                    setMemberData(response.data)
                    setMainData(response.data.order.filter(user => user.id === pr.id)[0])
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    // console.log(mainData)

    return (
        <>
            <div id="outer-container" style={{}}>

                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <Header page="home" />
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-11 col-lg-12">
                                    <div className="mx-3 mx-md-5">

                                        <div className="my-4" style={{ borderBottom: '1px solid #000' }}>
                                            <div className="mb-3"><h1 className="text-green weight-bold">Payment Confirmation</h1></div>
                                            <div className="row align-items-end">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="mb-4">
                                                        <b>First name</b> <span className="ms-4">{memberData.firstName}</span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <b>Email</b> <span className="ms-4">{memberData.email}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="mb-4">
                                                        <b>Last name</b> <span className="ms-4">{memberData.lastName}</span>
                                                    </div>
                                                    <div className="mb-4">
                                                        {/* <b>Institution</b> <span className="ms-4">Thammasat University</span> */}
                                                        <b>Affiliation</b> <span className="ms-4">{memberData.affiliation}</span>
                                                    </div>

                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="mb-4">
                                                        <b>Country</b> <span className="ms-4">{memberData.country && memberData.country.countryName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-4">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-10 col-lg-10">
                                                    <div className="box-registrat" style={{ backgroundImage: `url(${calendar})` }}>
                                                        <div className="position-relative box-green py-2 d-block text-center">
                                                            <h2 className="m-0 weight-bold">PAY FOR REGISTRATION</h2>
                                                        </div>
                                                        <div className="px-4 pt-4 pb-5">
                                                            <div className="mb-4 py-3 px-3" style={{ background: '#E2D4B4', borderRadius: '5px' }}>
                                                                <h3 className="m-0">EAEA2024</h3>
                                                                <div>19th East Asian Economic Association International conference</div>
                                                            </div>
                                                            <hr />
                                                            <div className="row align-items-end mt-4">
                                                                <div className="col-6 col-md-8 col-lg-8">
                                                                    <h3 className="m-0 weight-bold text-green">Total amount</h3>
                                                                </div>
                                                                <div className="col-6 col-md-4 col-lg-4 text-end">
                                                                    <h3 className="m-0 weight-bold">{memberData.symbol}{parseFloat(mainData.price).toLocaleString()}</h3>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="mt-4">
                                                        Please note that this message is only an acknowledgement of your transaction. Payment will be effective only upon the Bank's receipt of the settlement instruction from the merchant.  This payment does not include any fees charged by your card issuer. Please check the final payment on your credit card bill.  Please do not reply directly to this automatically generated message.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
