import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';

import Loading from "../layout/loading";
import { authUser, fetchLogout, initAuth } from '../services/authenService';

// import Footer from "../../layout/footer"


import banner from "../assets/img/banner.jpg";
import { Link } from "react-router-dom";

const Main = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [auth, setAuth] = useState(authUser())
    let componentMounted = useRef(true);

    useEffect(() => {

        if (componentMounted.current) {
            if (localStorage.getItem('accessToken')){
                window.location.href = "/choose";   
            }else{
              setIsLoading(false)
            }         
        }

        return () => {
            componentMounted.current = false;
        }
    }, [])

    return (
        <>
            <div id="outer-container" style={{}}>
                <div className="wrapper bg-main vh-100" style={{backgroundImage: `url(${banner})`}}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    {/* <img src={banner} className="w-100" alt="" /> */}
                                    <h2 className="my-3 m-md-5 weight-bold">EAEA2024</h2>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 text-center mt-5">
                                    <h1 className="text-green weight-bold">WELCOME</h1>
                                    <h3 className="weight-bold">19th East Asian Economic Association International conference</h3>
                                    {/* <h5 className="weight-medium">Early Bird Registration: 1 July –15 August 2024</h5> */}
                                    <h5 className="weight-medium">Registration and payment are now closed</h5>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 text-center mt-5">
                                    <Link to="/login" className="btn-submit weight-bold d-inline-block mx-1" style={{width:'150px'}}>LOG IN</Link>
                                    {/* <Link to="/signup" className="btn-submit weight-bold d-inline-block mx-1" style={{width:'150px'}}>SIGN UP</Link> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
